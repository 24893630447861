import axios from "axios";
import stores from "../stores";

class ApiClient {
  static instance = null;

  static axiosInstance = axios.create({
    // baseURL: "https://api.nikbakhtkhodro.com/api/v1/",
    // baseURL: "https://api.nikbakhtkhodro.com/api/v1/",
    baseURL: "https://apinikbakht.sahraboy.ir",
    // baseURL: "https://api.nikbakhtkhodro.com/api/v1/",
    // baseURL: "http://localhost:8000/",
    // baseURL: process.env.BASE_URL,
    // baseURL: "http://localhost:8000",
    // process.env.NODE_ENV !== "production"
    //   ? "https://api.nikbakhtkhodro.com/api/v1/"
    //   : "https://nikbakht.sahraboy.ir/api/",
    //'http://192.168.1.19:8000/api/v1/'
    //'http://192.168.1.19:8000/api/v1/'
    timeout: 30000,
    headers: {
      "Content-Type": "application/json",
      Token: stores.AccountStore.token,
    },
  });

  /**
   * @returns {getInstance}
   */
  static getInstance() {
    this.axiosInstance.defaults.headers["Token"] = stores.AccountStore.token;
    if (this.instance === null) {
      this.instance = new ApiClient();
    }
    return this.instance;
  }

  get(apiName, callBack, params, hideLog = false) {
    if (
      !hideLog &&
      (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
    )
      console.log(" Request GET: ", apiName, params);

    ApiClient.axiosInstance.defaults.headers["Token"] =
      stores.AccountStore.token;

    ApiClient.axiosInstance
      .get(apiName, { params: params })
      .then((response) => {
        this.responseHandler(response, callBack, apiName, hideLog);
      })
      .catch((error) => this.errorHandler(error, callBack, apiName, hideLog));
  }

  post(apiName, callBack, params, hideLog = false) {
    if (
      !hideLog &&
      (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
    )
      console.log(" Request : POST", apiName, params);

    ApiClient.axiosInstance.defaults.headers["Token"] =
      stores.AccountStore.token;

    ApiClient.axiosInstance
      .post(apiName, params)
      .then((response) => {
        this.responseHandler(response, callBack, apiName);
      })
      .catch((error) => this.errorHandler(error, callBack, apiName));
  }

  put(apiName, callBack, params) {
    // if (__DEV__) console.log(' Request : POST', apiName, params);
    ApiClient.axiosInstance.defaults.headers["Token"] =
      stores.AccountStore.token;

    ApiClient.axiosInstance
      .put(apiName, params)
      .then((response) => {
        this.responseHandler(response, callBack, apiName);
      })
      .catch((error) => this.errorHandler(error, callBack, apiName));
  }

  postFile(apiName, callBack, data, onUploadProgress) {
    // if (__DEV__) console.log(' Request : MULTIPART', apiName, data);
    console.log(stores.AccountStore.token);
    ApiClient.axiosInstance.defaults.headers["Token"] =
      stores.AccountStore.token;

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      timeout: 120000,
    };
    ApiClient.axiosInstance
      .post(apiName, data, { ...config, onUploadProgress: onUploadProgress })
      .then((response) => {
        this.responseHandler(response, callBack, apiName);
      })
      .catch((error) => {
        this.errorHandler(error, callBack, apiName);
      });
  }

  delete(apiName, callBack, params) {
    // if (__DEV__) console.log(' Request : DELETE', apiName, params);

    ApiClient.axiosInstance.defaults.headers["Token"] =
      stores.AccountStore.token;

    ApiClient.axiosInstance
      .delete(apiName, { params: params })
      .then((response) => {
        this.responseHandler(response, callBack, apiName);
      })
      .catch((error) => this.errorHandler(error, callBack, apiName));
  }

  errorHandler(error, callBack, apiName, hideLog = false) {
    if (!hideLog)
      console.log(
        "---* Response Error: ",
        apiName,
        error,
        "body:",
        error.response
      );

    if (
      error.response &&
      error.response.status &&
      error.response.status.toString() === "401"
    ) {
      stores.AccountStore.signOut();
      // window.location = "http://localhost:3000/nk_admin";
      // window.location = "https://nikbakht.sahraboy.ir/api/";
      window.location = "https://nikbakhtkhodro.com/nk_admin/";
    }
    console.log("ie: ", error);
    let msg = "خطایی درخ داده است لطفا مجددا تلاش نمایید.";
    if (error.response && error.response.data) {
      if (error.response.data.data && Array.isArray(error.response.data.data)) {
        msg = "";
        error.response.data.data.forEach((v) => {
          msg += " * " + (v.msg + "\n");
        });
      } else if (error.response.data && error.response.data.message) {
        msg = error.response.data.message;
      }

      error.response["message"] = msg;
      callBack(true, error.response);
    } else {
      callBack(true, { message: msg });
    }
  }

  responseHandler(response, callBack, apiName, hideLog = false) {
    if (
      !hideLog &&
      (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
    )
      console.log("+++* Response Success: ", apiName, response);

    let error = true;
    let data = response.data;

    if (data.success) {
      error = false;
    }

    callBack(error, data);
  }
}

export { ApiClient };
